<template>
    <div class="bg-color-f rounded-lg p-5 h-fit mb-5 mr-3 w-full relative">
        <div class="absolute top-2 right-2 z-10 flex">
            <PencilIcon @click="updateChart" class="w-5 h-5 mr-5 hover:cursor-pointer"></PencilIcon>
            <LoadingAnimation1 v-if="loading === 'deleting'"></LoadingAnimation1>
            <TrashIcon v-else @click="deleteChart" class="w-5 h-5 hover:cursor-pointer"></TrashIcon>
        </div>
        <div v-if="loading === 'timerange'" class="absolute inset-0 bg-white/50 flex items-center justify-center z-20">
            <LoadingAnimation1></LoadingAnimation1>
        </div>
        <VuePlotly
            :key="key"
            :data="data"
            :layout="layout"
            :config="config"
        ></VuePlotly>
    </div>
    <UpdateTemplate v-if="modal === 'edit'"
        :dataID="dataID"
        :index="index"
        :uid="uid"
        :page="page"
        :templateID="templateID"
        :widget="'charts'"
        :topic="templateData.title"
        :subcollection="subcollection"
        :values="modalData"
        @close="closeModal"
        @widget="editChart"
    ></UpdateTemplate>
</template>
  
<script>
    import { useStore } from 'vuex';
    import { ref, onMounted, watch, onBeforeUnmount } from 'vue';
    import { VuePlotly } from 'vue3-plotly';
    import { getPastTimestamp, utcToLocal, dateToString } from '@/tools/TimeFunctions';
    import { collection, query, orderBy, limit, getDocs, where} from 'firebase/firestore';
    import { db, auth } from '@/firebase';
    import { TrashIcon, PencilIcon } from '@heroicons/vue/24/solid';

    import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
    import UpdateTemplate from '../modals/updateTemplate.vue';
  
    export default {
        name: 'TimeSeries',
        components: {
            VuePlotly,
            TrashIcon,
            PencilIcon,
            LoadingAnimation1,
            UpdateTemplate,
        },
        props: {
            page: String,
            index: Number,
            templateID: String,
            template: Object,
            dataID: String,
            live: Boolean,
            timeRange: {
                type: Array,
                default: () => [getPastTimestamp(24, 'local'), dateToString(new Date())]
            }
        },
        emits: ['newMessageUTC', 'error', 'delete', 'modal', 'dataRequested', 'newTimeStamp'],
        setup(props, { emit }) {
            const key = ref(0);
            const subcollection = ref(Object.keys(props.template.specifications)[0]);
            const data = ref([]);
            const config = ref({displaylogo: false})
            const loading = ref(null);
            const modal = ref(null);
            const modalData = ref([]);
            const templateData = ref(props.template);
            const layout = ref(null);
            const workers = ref({});
            const unsubscribes = ref({});
            const initialLoadComplete = ref(false);
            const expectedDataSeries = ref(0);
            const loadedDataSeries = ref(0);
            const workerHealthCheck = ref(null);

            const store = useStore();
            const uid = ref(store.state.user.uid);

            const initializeChart = onMounted(() => {
                setLayout();
                data.value = [];
                grabData();
            });

            onBeforeUnmount(() => {
                console.log('Component unmounting, cleaning up workers...');
                
                // Clear health check interval first
                if (workerHealthCheck.value) {
                    clearInterval(workerHealthCheck.value);
                    workerHealthCheck.value = null;
                }

                // Clean up each worker
                Object.entries(workers.value).forEach(([subcollection, worker]) => {
                    try {
                        console.log(`Terminating worker for ${subcollection}...`);
                        // Send cleanup message and wait briefly before termination
                        worker.postMessage({ 
                            type: 'CLEANUP',
                            payload: { subcollection }
                        });
                        
                        // Force terminate after a short delay
                        setTimeout(() => {
                            worker.terminate();
                            delete workers.value[subcollection];
                        }, 100);
                    } catch (error) {
                        console.error(`Error cleaning up worker for ${subcollection}:`, error);
                    }
                });

                // Clear other subscriptions
                Object.values(unsubscribes.value).forEach(unsubscribe => {
                    if (unsubscribe) unsubscribe();
                });
            });

            const setLayout = () => {
                layout.value = {
                    showlegend: true,
                    legend: {
                        x: 0,
                        y: -0.8,
                        orientation: 'h',
                    },
                    title: {
                        text: templateData.value.title,
                        font: {
                            size: 25,
                            weight: 'bold',
                        },
                        xref: 'paper',
                        x: 0,
                        xanchor: 'left',
                        y: 1,
                        yanchor: 'top',
                        pad: { t: 10, b:10},
                    },
                    annotations: [
                        {
                            text: 'Broken',
                            xref: 'paper',
                            yref: 'paper',
                            x: 0.0,
                            y: -1.0,
                            showarrow: false,
                        },
                    ],
                    xaxis: {
                        autorange: false,
                        range: props.timeRange,
                        mirror: true,
                        ticks: 'outside',
                        showline: true,
                        type: 'date',
                        rangeselector: {
                            buttons: [
                                {
                                    count: 24,
                                    label: '24hr',
                                    step: 'hour',
                                    stepmode: 'backward',
                                },
                                {
                                    count: 12,
                                    label: '12hr',
                                    step: 'hour',
                                    stepmode: 'backward'
                                },
                                {
                                    count: 30,
                                    label: '30min',
                                    step: 'minute',
                                    stepmode: 'backward'
                                },
                                {
                                    count: 10,
                                    label: '10min',
                                    step: 'minute',
                                    stepmode: 'backward'
                                },
                            ]
                        },
                        rangeslider: {
                            visible: true,
                            range: props.timeRange,
                            borderwidth: 1,
                            bordercolor: 'black'
                        },
                    },
                    yaxis: {
                        autorange: true,
                        mirror: true,
                        ticks: 'outside',
                        showline: true,
                        type: 'linear'
                    },
                }
            };

            const handleWorkerMessage = (event) => {
                const { type, payload } = event.data;

                if (type === 'WORKER_FAILED') {
                    console.log(`Worker failed for ${payload.subcollection}, restarting...`);
                    // Clean up old worker
                    if (workers.value[payload.subcollection]) {
                        workers.value[payload.subcollection].terminate();
                        delete workers.value[payload.subcollection];
                    }
                    // Reinitialize worker
                    initializeWorker(payload.subcollection).then(() => {
                        startSnapshotWorker(payload.subcollection, 'LIVE_DATA');
                    });
                }
                // Handle all data added events
                if (type === 'DATA_ADDED') {
                    console.log('Received processed data:', payload);

                    // If there is processed data, add it to the chart
                    if (payload.data?.processed) {
                        let needsUpdate = false;
                        const twentyFourHoursAgo = getPastTimestamp(24, 'local');

                        // For each series, add the data to the chart
                        payload.data.processed.forEach(series => {
                            const foundData = data.value.find(item => item.name === series.name);
                            if (foundData) {

                                // Add new data points to the series
                                foundData.x.push(...series.x);
                                foundData.y.push(...series.y);

                                // Clean up old data points only after initial load
                                if (initialLoadComplete.value) {
                                    // Find the index where data is newer than 24 hours ago
                                    const cutoffIndex = foundData.x.findIndex(time => time >= twentyFourHoursAgo);
                                    if (cutoffIndex > 0) {
                                        // Slice the arrays to keep only data within 24 hours
                                        foundData.x = foundData.x.slice(cutoffIndex);
                                        foundData.y = foundData.y.slice(cutoffIndex);
                                        needsUpdate = true;
                                    }
                                }
                            }
                        });

                        // Update the last update annotation
                        if (payload.data.lastUpdate) {
                            layout.value.annotations[0].text = `Last Update: ${payload.data.lastUpdate}`;
                            needsUpdate = true;
                        }

                        // Track loaded series and set initialLoadComplete when all series are loaded
                        if (!initialLoadComplete.value) {
                            loadedDataSeries.value += payload.data.processed.length;
                            if (loadedDataSeries.value >= expectedDataSeries.value) {
                                initialLoadComplete.value = true;
                            }
                            needsUpdate = true;
                        }

                        // Only trigger re-render if necessary
                        if (needsUpdate) {
                            key.value++;
                        }
                    }
                }
                else if (type == 'TIME_RANGE_DATA') {
                    for (let doc of payload.data) {
                        for (let obj of templateData.value.specifications[payload.subcollection]) {
                            const foundData = data.value.find(item => item.name === obj.friendlyName);
                            if (foundData) {
                                foundData.x.push(utcToLocal(doc.messageUTC, '24hour'));
                                foundData.y.push(doc[obj.field]);
                            }
                        }
                    }
                    key.value++;
                }
            };

            const initializeWorker = async (subcollection) => {
                workers.value[subcollection] = new Worker(
                    new URL('@/components/WebWorkers/databaseActions/timeSeriesStreaming.js', import.meta.url),
                    { type: 'module' }
                );

                const serializableSpecs = templateData.value.specifications[subcollection].map(spec => ({
                    friendlyName: spec.friendlyName,
                    field: spec.field,
                    color: spec.color,
                    unit: spec.unit,
                    calculation: spec.calculation,
                    formula: spec.calculation ? spec.formula : null,
                    variables: spec.calculation ? spec.variables : null
                }));

                workers.value[subcollection].postMessage({
                    type: 'INITIALIZE',
                    payload: {
                        authToken: await auth.currentUser.getIdToken(),
                        subject: subcollection,
                        specifications: serializableSpecs
                    }
                });

                workers.value[subcollection].onmessage = handleWorkerMessage;
            };

            const grabData = () => {
                let totalSeries = 0;
                for (let subcollection in templateData.value.specifications) {
                    totalSeries += templateData.value.specifications[subcollection].length;
                    initializeWorker(subcollection);
                    
                    for (let obj of templateData.value.specifications[subcollection]) {
                        data.value.push({
                            type: "scatter",
                            mode: "lines+markers",
                            name: obj.friendlyName,
                            x: [],
                            y: [],
                            line: {
                                color: obj.color
                            },
                            marker: {
                                size: 6,
                                symbol: 'circle',
                                maxdisplayed: 50,
                            },
                            hovertemplate: `%{x} = %{y} ${obj.unit || ''}`,
                        });
                    }
                    getLastUpdate(subcollection);
                    startSnapshotWorker(subcollection);
                }
                expectedDataSeries.value = totalSeries;
            };

            const getLastUpdate = async(subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy("messageUTC", "desc"), limit(1));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    layout.value.annotations[0].text = `Last Update: ${utcToLocal(doc.data().messageUTC, '12hour')}`;
                });
                key.value += 1;
            };

            const startSnapshotWorker = async (subcollection, type = 'LIVE_DATA', timeRange = props.timeRange[0]) => {
                let payload;
                if (type === 'LIVE_DATA') {
                    payload = {
                        authToken: await auth.currentUser.getIdToken(),
                        body: {
                            dataID: props.dataID,
                            subject: subcollection,
                            filter: [{
                                field: 'messageUTC',
                                operator: '>',
                                value: timeRange
                            }],
                            order: [{
                                field: 'messageUTC',
                                direction: 'desc'
                            }]
                        }
                    }
                } else if (type === 'TIME_RANGE') {
                    payload = {
                        authToken: await auth.currentUser.getIdToken(),
                        body: {
                            dataID: props.dataID,
                            subject: subcollection,
                            filter: [
                                {
                                    field: 'messageUTC',
                                    operator: '>=',
                                    value: timeRange[0]
                                },
                                {
                                    field: 'messageUTC',
                                    operator: '<=',
                                    value: timeRange[1]
                                }
                            ],
                            order: [
                                {
                                    field: 'messageUTC',
                                    direction: 'asc'
                                }
                            ]
                        }
                    }
                }
                workers.value[subcollection].postMessage({
                    type: type,
                    payload: payload
                });
            };

            watch(() => props.timeRange, (newTimeRange) => {
                layout.value.xaxis.range = newTimeRange;
                layout.value.xaxis.rangeslider.range = newTimeRange;
                key.value++;
            }, { deep: true });

            const updateChart = () => {
                emit('modal', 'edit');
                let index = 0;
                console.log(templateData.value.specifications);
                for (let key of Object.keys(templateData.value.specifications)) {
                    for (let obj of templateData.value.specifications[key]) {
                        modalData.value[index] = { ...obj, source: key };
                        index += 1;
                    }
                }
                modal.value = 'edit';
            };

            const deleteChart = async() => {
                loading.value = 'deleting';

                let responseData;
                try {
                    const body = {
                        uid: uid.value,
                        templateID: props.templateID,
                        dashboard: props.page,
                        widgetType: 'charts',
                        widgetID: props.index,
                    };

                    const response = await fetch(`https://us-central1-mesh-db-stg.cloudfunctions.net/templates`, {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(body),
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;

                if (responseData.code === 200) {
                    emit('delete');
                } else {
                    console.log(responseData);
                    emit('error', responseData.message);
                }
            };

            const closeModal = () => {
                modal.value = null;
                emit('modal', null);
            };

            const editChart = async(template) => {
                templateData.value = template;
                setLayout();
                data.value = [];
                grabData();
            };

            const emitData = () => {
                emit('dataRequested', data.value);
            };

            const setCustomTimeRange = async (customRange) => {
                if (customRange && customRange.length === 2) {
                    loading.value = 'timerange';  // Set loading state
                    try {
                        Object.values(workers.value).forEach(worker => {
                            worker.terminate();
                        });
                        workers.value = {};

                        layout.value.xaxis.range = customRange;
                        layout.value.xaxis.rangeslider.range = customRange;
                        key.value++;

                        for (let subcollection in templateData.value.specifications) {
                            data.value.forEach(series => {
                                series.x = [];
                                series.y = [];
                            });
                            let subRef = collection(db, 'data', props.dataID, subcollection);
                            const q = query(subRef, orderBy("messageUTC", "asc"), where("messageUTC", ">=", customRange[0]), where("messageUTC", "<=", customRange[1]));
                            const querySnapshot = await getDocs(q);
                            querySnapshot.forEach((doc) => {
                                const docData = doc.data();
                                for (let obj of templateData.value.specifications[subcollection]) {
                                    const foundData = data.value.find(item => item.name === obj.friendlyName);
                                    if (foundData) {
                                        foundData.x.push(utcToLocal(docData.messageUTC, '24hour'));
                                        foundData.y.push(docData[obj.field]);
                                    }
                                }
                            });
                            key.value++;
                        }
                    } catch (error) {
                        console.error('Error fetching custom time range data:', error);
                        emit('error', 'Failed to fetch time range data');
                    } finally {
                        loading.value = null;  // Clear loading state
                    }
                }
            };

            return {
                uid,
                loading,
                modal,
                modalData,
                templateData,
                subcollection,
                data,
                layout,
                key,
                config,
                workerHealthCheck,
                initializeChart,
                updateChart,
                deleteChart,
                editChart,
                closeModal,
                emitData,
                setCustomTimeRange,
            };
        },
    };
</script>

<style>
</style>