<template>
    <DefaultLayout
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    :subpageLinks="subpageLinks"
    >
    <div class="flex flex-wrap h-full">
        <div class="flex w-full">
            <div class="flex max-w-[700px] justify-around flex-wrap mb-[20px]">

                <!-- Add gauges -->
                <GenericGauge v-for="(gauge, index) in gauges" :key="index"
                    :dataID="dataID"
                    :template="gauge"
                    :timeFormat="timeFormat"
                ></GenericGauge>

                <!-- Add statistics cards -->
                <StatsCard v-for="(statDetails, index) in infoCards" :key="index" class="min-w-[325px]"
                    :statDetails="statDetails"
                    :dataID="dataID"
                    :timeFormat="timeFormat"
                ></StatsCard>
            </div>

            <!-- Add historical map -->
            <div class="right-container">
                <UnitMap v-for="(mapDetails, index) in maps" :key="index"
                    :dataID="dataID"
                    :template="mapDetails"
                    :timeFormat="timeFormat"
                ></UnitMap>
            </div>
        </div>

        <!-- Add generic tables -->
        <div v-for="(table, index) in tables" :key="index" class="my-[10px] w-[99%]">
            <GenericTable v-if="table.type === 'generic'"
                :dataID="dataID"
                :template="table"
                :timeFormat="timeFormat"
            ></GenericTable>
        </div>

        <!-- Add more statistics cards -->
        <StatsCard v-for="(statDetails, index) in statistics" :key="index" class="min-w-[325px]"
            :statDetails="statDetails"
            :dataID="dataID"
            :timeFormat="timeFormat"
        ></StatsCard>

        <!-- Add event log -->
        <div class=" flex-grow mr-5" :class="statistics.length ? 'flex-grow' : 'flex-grow'">
            <EventLog style="margin-bottom: 15px;"
                :dataID="dataID"
                :timeFormat="timeFormat"
            ></EventLog>
        </div>
    </div>
  
    </DefaultLayout>
</template>
    
<script>
    import { useStore } from 'vuex';
    import { onMounted, ref } from 'vue';
    import { router } from '@/router';
    import { doc, getDoc, onSnapshot} from 'firebase/firestore';
    import { db } from '@/firebase';
    import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

    import DefaultLayout from '@/layouts/Layout.vue';
    import GenericGauge from '@/components/charts/Guage.vue';
    import StatsCard from '@/components/cards/StatsCard.vue';
    import GenericTable from '@/components/tables/GenericTable.vue';
    import EventLog from '@/components/tables/EventLog.vue';
    import UnitMap from '@/components/maps/UnitMap.vue';
    
    export default {
        name: 'UnitDashboard',
        components: {
            DefaultLayout,
            GenericGauge,
            StatsCard,
            GenericTable,
            EventLog,
            UnitMap
        },
        setup() {
            // Set up reference variables.
            const pageTitle = ref('');
            const pageSubtitle = ref('');
            const subpageLinks = ref(new Map());
            const statistics = ref(new Object());
            const gauges = ref(new Object());
            const tables = ref(new Object());
            const infoCards = ref(new Object());
            const maps = ref(new Object());

            // Set up all other variables
            const store = useStore();
            const timeFormat = store.state.user.timeFormat;
            const dataID = router.currentRoute.value.params.dataID;
            let defaultTemplate;

            onMounted(async () => {
                await getUnitDetails();
                getTemplate();
                checkActivity();
            });

            /**
             * Grabs the unit details and sets up the page title
             */
            const getUnitDetails = async() => {
                
                // Attempt the data document using the data ID
                try {
                    const docRef = doc(db, "data", dataID);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        const unitDetails = docSnap.data();
                        defaultTemplate = unitDetails.defaultTemplate;
                    }
                    
                } catch(error) {
                    console.log(error)
                    pageTitle.value = dataID;
                    pageSubtitle.value = "Faild to retrieve data";
                }
            };

            /**
             * Grabs the template for the unit and adds additional subpage links
             */
            const getTemplate = async() => {

            // The template is not stored so grab it from the database.
                try {
                    console.log('defaultTemplate =', defaultTemplate);
                    const docRef = doc(db, "templates", defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Grab the statistics.
                        statistics.value = templateData.overview.statistics || [];
                        tables.value = templateData.overview.tables;
                        gauges.value = templateData.overview.gauges;
                        infoCards.value = templateData.overview.infoCards;
                        maps.value = templateData.overview.maps;

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [null, 'inactive']);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/time-series`]);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [`/dashboard/${dataID}/events`]);
                        }

                        // subpageLinks.value.set('Management', [`/dashboard/${dataID}/management`]);
                    }

                } catch(error) {
                    console.log(error);
                }            
            };

            const checkActivity = () => {
                // Start a snapshot listener
                onSnapshot(doc(db, "data", dataID), (document) => {
                    const unitDetails = document.data();

                    if (unitDetails.friendlyName !== "") {
                        pageTitle.value = unitDetails.friendlyName;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold">Data ID: </span>
                            ${dataID}
                            <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    
                    } else {
                        pageTitle.value = dataID;
                        pageSubtitle.value = `<p>
                            <span style="font-weight: bold;">Last Active: </span>
                            ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                            </p>`;
                    }
                });
            };

            return {
                pageTitle,
                pageSubtitle,
                subpageLinks,
                dataID,
                timeFormat,
                statistics,
                gauges,
                infoCards,
                tables,
                maps
            }
        }
    };
</script>
    
<style>

    .unit-dashboard-body {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        /* height: fit-content; */
    }

    .left-container {
        max-width: 700px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .right-container {
        flex-grow: 1;
        /* min-width: 500px; */
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 40px;
        /* height: 485px; */
    }

    .statistics-container {
        width: 50%;
        background-color: white;
        border-radius: 10px;
        margin-right: 20px;
        height: 583px;
    }

    .gauge-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-around;
        gap: 10px;
    }

    .unit-state-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-around;
        gap: 20px;
    }

    .custom-stat {
        background-color: white;
        width: 300px;
        border-radius: 5px;
        /* padding: 20px; */
        display: flex;
    }

    .diagnostics-container {
        margin-bottom: 40px;
        width: 100%;
    }

    .statistics-body {
        height: 81%;
        overflow: auto;
    }

    .dashboard-container-1 {
        flex-grow: 1;
    }

    .dashboard-container-2 {
        display: flex;
        width: 100%;
    }
</style>